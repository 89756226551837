<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#FDCE48"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#FDCE48" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #000">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #000">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-dialog
      v-model="addUserDialog"
      persistent
      :width="
        $vuetify.breakpoint.name == 'xs' ||
        $vuetify.breakpoint.name == 'sm' ||
        $vuetify.breakpoint.name == 'md'
          ? '100vw'
          : $vuetify.breakpoint.name == 'lg'
          ? '75vw'
          : '50vw'
      "
    >
      <v-card>
        <v-layout wrap justify-center pa-2>
          <v-flex xs12>
            <AddUser @stepper="winStepper" />
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="editUserDialog"
      persistent
      :width="
        $vuetify.breakpoint.name == 'xs' ||
        $vuetify.breakpoint.name == 'sm' ||
        $vuetify.breakpoint.name == 'md'
          ? '100vw'
          : $vuetify.breakpoint.name == 'lg'
          ? '75vw'
          : '50vw'
      "
    >
      <v-card>
        <v-layout wrap justify-center pa-2>
          <v-flex xs12>
            <EditUser
              v-bind:storage="editUserData"
              @stepper="winStepper"
              :key="editUserData._id"
            />
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <v-layout wrap justify-center>
      <v-flex xs12 text-left>
        <span class="itemHeading"> Bee Keepers </span>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-end>
      <v-flex xs12 sm2 lg2 xl1 px-2 align-self-center>
        <v-btn
          block
          tile
          depressed
          outlined
          color="#000000"
          :ripple="false"
          @click="addUserDialog = true"
          class="itemValue"
        >
          Add User
        </v-btn>
      </v-flex>
      <v-flex xs12 sm3 md2 lg2 xl2 px-2 py-2 py-sm-0 align-self-center>
        <v-autocomplete
          outlined
          dense
          color="#000000"
          label="User Type"
          v-model="userType"
          :items="userTypes"
          item-color="#000000"
          item-text="name"
          item-value="value"
          hide-details
        >
          <template v-slot:selection="{ item }">
            {{ item.name }}
          </template>
          <template v-slot:item="{ item }">
            <span class="text-left">
              {{ item.name }}
            </span>
          </template>
        </v-autocomplete>
      </v-flex>
      <v-flex xs12 sm2 lg2 xl1 px-2 align-self-center>
        <v-autocomplete
          outlined
          dense
          color="#000000"
          label="Status"
          v-model="status"
          :items="statuses"
          item-color="#000000"
          hide-details
        >
          <template v-slot:item="{ item }">
            <span class="text-left">
              {{ item }}
            </span>
          </template>
        </v-autocomplete>
      </v-flex>
      <v-flex xs12 sm3 lg3 xl2 px-2 align-self-center pt-2 pt-sm-0>
        <v-form @submit.prevent="appSearch">
          <v-text-field
            class="searchBox"
            v-model="searchKey"
            prepend-inner-icon="mdi-magnify"
            placeholder="Search Users"
            solo
            flat
            hide-details
            color="#FDCE48"
          ></v-text-field>
        </v-form>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-start py-4>
      <template v-for="(item, i) in users">
        <v-flex xs12 xl6 pa-2 :key="i">
          <UserItem v-bind:storage="item" @stepper="winStepper" />
        </v-flex>
      </template>
      <v-flex xs12 v-if="users.length < 1">
        <span class="itemKey"> No data available </span>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center v-if="pages > 1">
      <v-flex xs12>
        <v-pagination
          v-model="currentPage"
          :length="pages"
          :total-visible="7"
          color="#919191"
        ></v-pagination>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import UserItem from "./usersItem";
import AddUser from "./addUser";
import EditUser from "./editUser";
export default {
  components: {
    UserItem,
    AddUser,
    EditUser,
  },
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      users: [],
      pages: 0,
      count: 20,
      currentPage: this.$route.query.page ? Number(this.$route.query.page) : 1,
      searchKey: this.$route.query.searchKey
        ? this.$route.query.searchKey
        : null,
      userType: this.$route.query.userType
        ? this.$route.query.userType
        : "User",
      userTypes: [
        { name: "User", value: "User" },
        { name: "Supervisor", value: "Supervisor" },
        { name: "Admin", value: "Admin" },
      ],
      status: this.$route.query.status ? this.$route.query.status : "Active",
      statuses: ["Active", "Banned"],
      addUserDialog: false,
      editUserDialog: false,
      editUserData: {},
    };
  },
  watch: {
    currentPage() {
      this.$router.push({
        path: "/Admin/Users",
        query: {
          page: this.currentPage,
          userType: this.$route.query.user,
          status: this.status,
          searchKey: this.searchKey,
        },
      });
    },
    userType() {
      this.changePage();
    },
    status() {
      this.changePage();
    },
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    changePage() {
      this.$router.push({
        path: "/Admin/Users",
        query: {
          userType: this.userType,
          status: this.status,
          searchKey: this.searchKey,
        },
      });
    },
    appSearch() {
      if (!this.searchKey) this.searchKey = null;
      this.$router.push({
        path: "/Admin/Users",
        query: {
          userType: this.$route.query.userType,
          status: this.status,
          searchKey: this.searchKey,
        },
      });
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/user/allUsers",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          role: this.userType,
          page: this.currentPage,
          count: this.count,
          key: this.$route.query.searchKey,
          status: this.status,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.users = response.data.data;
            this.pages = response.data.pages;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    winStepper(windowData) {
      if (windowData.type == "Add") {
        if (windowData.getData) this.getData();
        if (!windowData.addUser) this.addUserDialog = false;
      } else if (windowData.type == "Edit") {
        if (windowData.userData) this.editUserData = windowData.userData;
        if (windowData.getData) this.getData();
        this.editUserDialog = windowData.editUser;
      } else if (windowData.type == "Delete") {
        if (windowData.pageResponse.status) {
          this.msg = "User Deleted Successfully";
          this.showSnackBar = true;
          if (windowData.getData) this.getData();
        } else {
          this.msg = "User not Deleted";
          this.showSnackBar = true;
        }
      } else if (windowData.type == "Ban") {
        if (windowData.pageResponse.status) {
          this.msg = "User Banned Successfully";
          this.showSnackBar = true;
          if (windowData.getData) this.getData();
        } else {
          this.msg = "User not Banned";
          this.showSnackBar = true;
        }
      } else if (windowData.type == "Activate") {
        if (windowData.pageResponse.status) {
          this.msg = "User Activated Successfully";
          this.showSnackBar = true;
          if (windowData.getData) this.getData();
        } else {
          this.msg = "User not Activated";
          this.showSnackBar = true;
        }
      }
    },
  },
};
</script>