<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#FDCE48"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-dialog v-model="deleteDialogue" width="400">
      <v-card>
        <v-layout pa-4 wrap justify-center>
          <v-flex xs12>
            <span
              style="
                color: #000000;
                font-family: opensanssemibold;
                font-size: 18px;
              "
              >Do you want to delete this user ?</span
            >
          </v-flex>
          <v-flex xs6 px-2 py-4>
            <v-btn
              outlined
              tile
              block
              depressed
              dark
              color="#C62525"
              @click="deleteUser"
              class="itemValue"
            >
              Yes
            </v-btn>
          </v-flex>
          <v-flex xs6 px-2 py-4>
            <v-btn
              tile
              block
              depressed
              dark
              color="#1FB436"
              @click="deleteDialogue = false"
              class="itemValue"
            >
              No
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <v-dialog v-model="banDialogue" width="400">
      <v-card>
        <v-layout pa-4 wrap justify-center>
          <v-flex xs12>
            <span
              style="
                color: #000000;
                font-family: opensanssemibold;
                font-size: 18px;
              "
            >
              Do you want to ban this user ?
            </span>
          </v-flex>
          <v-flex xs6 px-2 py-4>
            <v-btn
              outlined
              tile
              block
              depressed
              dark
              color="#C62525"
              @click="banUser"
              class="itemValue"
            >
              Yes
            </v-btn>
          </v-flex>
          <v-flex xs6 px-2 py-4>
            <v-btn
              tile
              block
              depressed
              dark
              color="#1FB436"
              @click="banDialogue = false"
              class="itemValue"
            >
              No
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <v-dialog v-model="activateDialogue" width="400">
      <v-card>
        <v-layout pa-4 wrap justify-center>
          <v-flex xs12>
            <span
              style="
                color: #000000;
                font-family: opensanssemibold;
                font-size: 18px;
              "
            >
              Do you want to activate this user ?
            </span>
          </v-flex>
          <v-flex xs6 px-2 py-4>
            <v-btn
              outlined
              tile
              block
              depressed
              dark
              color="#C62525"
              @click="activateUser"
              class="itemValue"
            >
              Yes
            </v-btn>
          </v-flex>
          <v-flex xs6 px-2 py-4>
            <v-btn
              tile
              block
              depressed
              dark
              color="#1FB436"
              @click="activateDialogue = false"
              class="itemValue"
            >
              No
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <v-card outlined>
          <v-layout wrap justify-center pa-2>
            <v-flex xs12 sm2 text-center align-self-center>
              <v-avatar color="#FFF9C4" size="70">
                <v-img v-if="storage.photo" :src="mediaURL + storage.photo">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="#FDCE48"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>
            </v-flex>
            <v-flex xs12 sm10>
              <v-layout wrap justify-start>
                <v-flex xs6>
                  <v-flex xs12 text-left>
                    <span class="itemKey">Name</span>
                  </v-flex>
                  <v-flex xs12 text-left>
                    <span class="itemValue">
                      {{ storage.firstname }} {{ storage.lastname }}
                      <span v-if="!storage.firstname">-</span>
                    </span>
                  </v-flex>
                </v-flex>
                <v-flex xs6>
                  <v-flex xs12 text-left>
                    <span class="itemKey">Username</span>
                  </v-flex>
                  <v-flex xs12 text-left>
                    <span class="itemValue">
                      {{ storage.username }}
                      <span v-if="!storage.username">-</span>
                    </span>
                  </v-flex>
                </v-flex>
                <v-flex xs6>
                  <v-flex xs12 text-left>
                    <span class="itemKey">Phone</span>
                  </v-flex>
                  <v-flex xs12 text-left>
                    <span class="itemValue">
                      {{ storage.phone }}
                      <span v-if="!storage.phone">-</span>
                    </span>
                  </v-flex>
                </v-flex>
                <v-flex xs6>
                  <v-flex xs12 text-left>
                    <span class="itemKey">Email</span>
                  </v-flex>
                  <v-flex xs12 text-left>
                    <span class="itemValue">
                      {{ storage.email }}
                      <span v-if="!storage.email">-</span>
                    </span>
                  </v-flex>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 sm12 xl12>
              <v-layout wrap justify-end py-2 py-xl-2>
                <v-flex xs6 sm3 xl2 px-2>
                  <v-btn
                    block
                    tile
                    small
                    depressed
                    color="#1885f2"
                    :ripple="false"
                    :to="'/Admin/Users/' + storage._id"
                    class="itemValue"
                  >
                    <span style="color: #ffffff"> View </span>
                  </v-btn>
                </v-flex>
                <v-flex xs6 sm3 xl2 px-2 py-xl-0>
                  <v-btn
                    block
                    tile
                    small
                    depressed
                    outlined
                    color="#666666"
                    :ripple="false"
                    @click="editUser"
                    class="itemValue"
                  >
                    <span style="color: #666666"> Edit </span>
                  </v-btn>
                </v-flex>
                <v-flex
                  xs6
                  sm3
                  xl2
                  px-2
                  pt-2
                  pt-sm-0
                  v-if="storage.status == 'Active'"
                >
                  <v-btn
                    block
                    tile
                    small
                    depressed
                    outlined
                    color="#FF1313"
                    :ripple="false"
                    @click="banDialogue = true"
                    class="itemValue"
                  >
                    <span style="color: #ff1313"> Ban User </span>
                  </v-btn>
                </v-flex>
                <v-flex
                  xs6
                  sm3
                  xl3
                  px-2
                  pt-2
                  pt-sm-0
                  v-if="storage.status == 'Banned'"
                >
                  <v-btn
                    block
                    tile
                    small
                    depressed
                    outlined
                    color="#FF1313"
                    :ripple="false"
                    @click="activateDialogue = true"
                    class="itemValue"
                  >
                    <span style="color: #ff1313"> Activate User </span>
                  </v-btn>
                </v-flex>
                <v-flex xs6 sm3 xl2 px-2 pt-2 pt-sm-0 pt-xl-0>
                  <v-btn
                    block
                    tile
                    small
                    depressed
                    outlined
                    color="#FF1313"
                    :ripple="false"
                    @click="deleteDialogue = true"
                    class="itemValue"
                  >
                    <span style="color: #ff1313"> Delete </span>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: ["storage"],
  data() {
    return {
      appLoading: false,
      ServerError: false,
      msg: null,
      deleteDialogue: false,
      banDialogue: false,
      activateDialogue: false,
    };
  },
  methods: {
    editUser() {
      this.$emit("stepper", {
        type: "Edit",
        editUser: true,
        userData: this.storage,
      });
    },
    deleteUser() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/user/removeuser",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id: this.storage._id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.deleteDialogue = false;
            this.$emit("stepper", {
              type: "Delete",
              getData: true,
              pageResponse: response.data,
            });
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    banUser() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/user/banuser",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id: this.storage._id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.banDialogue = false;
            this.$emit("stepper", {
              type: "Ban",
              getData: true,
              pageResponse: response.data,
            });
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    activateUser() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/user/activateuser",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id: this.storage._id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.activateDialogue = false;
            this.$emit("stepper", {
              type: "Activate",
              getData: true,
              pageResponse: response.data,
            });
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
  },
};
</script>